/* eslint-disable */
import { notificationConf } from "@/constant/config";
import axios from "axios";
const queryString = require('query-string');
export default {
    getEmailTask(request) {
        return axios({
            method: "GET",
            url: `${notificationConf.getEmailTask}?${queryString.stringify(request)}`,
            withCredentials: true,
        });
    },
    saveEmailTask(request) {
        return axios({
            method: "POST",
            url: `${notificationConf.saveEmailTask}`,
            data: request,
            withCredentials: true,
        });
    },
    startStopEmailTask(taskId, isRunning) {
        return axios({
            method: "GET",
            url: `${notificationConf.startStopEmailTask}?taskId=${taskId}&action=${isRunning ? 'start' : 'stop'}`,
            withCredentials: true,
        });
    },
    // Get Stripe history
    sendNotificationManual(request) {
        return axios({
            method: "POST",
            url: `${notificationConf.sendNotificationManual}`,
            data: request,
            withCredentials: true,
        });
    },
}